import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  // Title,
  // Tooltip,
  // Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,

);

export const options = {
  responsive: true,
  locale: 'en-US',
  // maintainAspectRatio: false, //height
  scales: {
    y: {
      //  min: 2000000,
      // max: 39000000,
    }
  },
  plugins: {
    legend: {

    },
    title: {
      display: true,
      text: 'DAO Vots',
    },
  },
};

const labels = ['DAO Proposal #1', 'DAO Proposal #2', 'DAO Proposal #3', 'DAO Proposal #4', 'DAO Proposal #5', 
'DAO Proposal #6', 'DAO Proposal #7', 'DAO Proposal #8', 'DAO Proposal #9', 'DAO Proposal #10'
, 'DAO Proposal #11', 'DAO Proposal #12', 'DAO Proposal #13', 'DAO Proposal #14'
,'DAO Proposal #15', 'DAO Proposal #16', 'DAO Proposal #17', 'DAO Proposal #18', 'DAO Proposal #19', 'DAO Proposal #20', 'DAO Proposal #21', 
'DAO Proposal #22', 'DAO Proposal #23'];
export const data = {
  labels,
  datasets: [
    {
      fill: true,
      lineTension: 0.5,
      // lineTension: 0.9,
      label: 'HDN',
      pointRadius: 4,
      pointHoverRadius: 8,
      pointBackgroundColor: "#1387F1",
      data: [10700000, 9900000, 11239000, 11576000, 17800000, 19500000, 25304000, 36900000, 20600000, 16500000, 16499000, 25500000, 10485000, 22118000
      , 22119000, 38300000, 21400000, 22210000, 13025000, 16759000, 16000000, 20400000, 18182000],
      borderColor: '#0B6FC9',
      backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "#0a60ae");
          gradient.addColorStop(1, "#1763ab14");
          return gradient;

        },
    },
    // {
    //   fill: true,
    //   label: 'Dataset 2',
    //   data: [18, 29, 32, 92, 48, 64, 89, 72],
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'red',
    // },
  ],
};




export default function GovernanceChart() {
  return (

    
    <div className='card card-style-2'>
      <div class="card-top d-flex justify-content-between align-items-center"><h3 class="card-title">Last 23 DAO Votes</h3><div class="card-value">371,675,000</div></div>


   <div className='chart'>
        <Line options={options} data={data} />
        </div>


</div>
  )
}

