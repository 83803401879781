import React from 'react'

export default function dashboard() {
    return (
        <>
            <section className='section section-cards-4'>
                <div className='container'>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-6 col-xl-3 col-lg-4 mb-4'>
                            <div className='search'>
                                <input className='form-control search-box' placeholder='Search Proposal'></input>
                                <button className='search-button'><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        <div className='col-6 mb-4'>
                            <div className='d-flex justify-content-end'>
                                <a className='btn btn-primary' href='#/home'><i class="ti-plus me-2"></i>New Proposal</a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-style-2'>
                                <table class="table proposal-table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='topic-row'>Topic</th>
                                            <th scope="col">Replies</th>
                                            <th scope="col">Views</th>
                                            <th scope="col" className='review-row'>Review</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>Hydranet Improvement Proposal 24: Approval of Hydranet DAO Roadmap</a></h3>

                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Locked</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>Hydranet improvement Proposal: Add Open Sourcing on the Hydranet Roadmap</a></h3>
                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>Voting on tokenomics V1 as the supported plan for the Hydranet projects near future</a></h3>
                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>Hydranet Improvement Proposal 24: Approval of Hydranet DAO Roadmap</a></h3>
                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className='topic-title'>
                                                <h3><a href='/proposal-detail'><i className="ti-lock me-2"></i>Hydranet Improvement Proposal 24: Approval of Hydranet DAO Roadmap</a></h3>
                                                <p>This proposal seeks approval from the DAO to present the Hydranet DAO's strategic roadmap for the next year, outlining our key initiatives and milestones from Q4 2023 to Q4 2024.</p>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                                                <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                                            </th>
                                            <td>8</td>
                                            <td>1240</td>
                                            <td>
                                                <ul className='stars'>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                    <li><button><i class="fa-regular fa-star voted"></i></button></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}
