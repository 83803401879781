import React from 'react';

import avatar1 from '../assets/avatar1.jpg';
import avatar2 from '../assets/avatar2.jpg';
import avatar3 from '../assets/avatar3.jpg';
import avatar4 from '../assets/avatar4.jpg';
import avatar5 from '../assets/avatar5.jpg';

export default function ProposalDetail() {


  return (
    <>

      <section className='section section-proposal-detail'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='proposal-details'>
                <div className='card card-style-2'>
                  <div className='proposal-header'>
                    <h1><i class="ti-lock me-2"></i>Hydranet Improvement Proposal 24: Approval of Hydranet DAO Roadmap</h1>

                    <ul class="stars">
                      <span className='me-2'>(82)</span>
                      <li><button><i class="fa-regular fa-star voted"></i></button></li>
                      <li><button><i class="fa-regular fa-star voted"></i></button></li>
                      <li><button><i class="fa-regular fa-star voted"></i></button></li>
                      <li><button><i class="fa-regular fa-star voted"></i></button></li>
                      <li><button><i class="fa-regular fa-star"></i></button></li>
                    </ul>
                  </div>

                  <div className='topic-owner'>
                    <div className='user-avatar'>
                      <img src={avatar1} alt='Avatar01'></img>
                    </div>
                    <div className='user-info'>
                      <span className='username'>Nicklas</span>
                      <p>Community Member</p>
                    </div>

                  </div>
                  <div className='proposal-content'>

                    <p>
                      Summary: This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s). This strategic move aims to enhance our token's visibility, liquidity, and accessibility to a broader audience as we aim for CEXs which are ranked ≈ #30 and higher (Coingecko and Coinmarketcap as sources).
                    </p>
                    <p>
                      Details: Purpose of 1 Million HDN Allocation:
                      The allocation is intended to cover listing fees, marketing and further associated expenses for exchange platform(s), ensuring our token's widespread availability.
                    </p>
                    <p>
                      USDT Usage & HDN Deduction:
                      In scenarios where USDT from the treasury is utilized for listing expenses, an equivalent amount of HDN, based on the prevailing market rate, will be set up as bonds and deducted from the 1 Million allocation. This ensures transparency and accountability in fund utilization.
                    </p>
                    <p>
                      Transparency and Reporting:
                      We commit to providing regular updates to the community regarding the utilization of these funds, ensuring transparency, and building trust.
                      Please note that listing in CEXs requires NDAs, which limits what can be said from our side. The multi-sig will add these total amounts used to a quarterly dao report starting Q1 2024.
                    </p>
                  </div>

                  <div className='d-flex justify-content-between align-items-center mt-4'>

                    <button className='btn btn-primary' title='reply'><i class="fa-solid fa-reply me-1"></i>Reply Now</button>

                    <div className='proposal-info d-flex justify-content-start'>
                      <span className='topic-status locked'>Active</span>
                      <span className='topic-started'><i className="ti-calendar me-2"></i>27 Sept, 2023</span>
                      <span className='topic-activity'><i className="ti-time me-2"></i>2d</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='replies'>
                <div className='post-container'>
                  <div className='card card-style-2'>
                    <div className='post'>
                      <div className='post-left'>
                        <div className='user-avatar'><img src={avatar2} alt='Avatar01'></img></div>
                      </div>
                      <div className='post-content'>
                        <div className='user-info ps-0'>
                          <span className='username'>Nicklas</span>
                          <span className='post-date'><i class="fa-regular fa-calendar me-1"></i>OCT 24, 2023</span>
                        </div>
                        <p>
                          Summary: This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s). This strategic move aims to enhance our token's visibility, liquidity, and accessibility to a broader audience as we aim for CEXs which are ranked ≈ #30 and higher (Coingecko and Coinmarketcap as sources).

                          Details: Purpose of 1 Million HDN Allocation:
                          The allocation is intended to cover listing fees, marketing and further associated expenses for exchange platform(s), ensuring our token's widespread availability.
                        </p>
                        <div className='post-bottom'>
                          <div className='post-bottom-left'>
                            <button className='reply-button' title='Reply'><i class="fa-solid fa-reply me-1"></i>Reply</button>
                            <span className='mx-3'><i class="fa-regular fa-heart me-1"></i>42</span>
                          </div>
                          <div className='post-bottom-right'>
                            <span className='like-buttons'><button><i class="fa-regular fa-thumbs-up"></i></button><button><i class="fa-regular fa-thumbs-down"></i></button></span>
                          </div>
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
                <div className='post-container'>
                  <div className='card card-style-2'>
                    <div className='post'>
                      <div className='post-left'>
                        <div className='user-avatar'><img src={avatar3} alt='Avatar01'></img></div>
                      </div>
                      <div className='post-content'>
                        <div className='user-info ps-0'>
                          <span className='username'>Nicklas</span>
                          <span className='post-date'><i class="fa-regular fa-calendar me-1"></i>OCT 24, 2023</span>
                        </div>
                        <p>
                          Summary: This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s). This strategic move aims to enhance our token's visibility, liquidity, and accessibility to a broader audience as we aim for CEXs which are ranked ≈ #30 and higher (Coingecko and Coinmarketcap as sources).

                          Details: Purpose of 1 Million HDN Allocation:
                          The allocation is intended to cover listing fees, marketing and further associated expenses for exchange platform(s), ensuring our token's widespread availability.
                        </p>
                        <div className='post-bottom'>
                          <div className='post-bottom-left'>
                            <button className='reply-button' title='Reply'><i class="fa-solid fa-reply me-1"></i>Reply</button>
                            <span className='liked mx-lg-3 mx-2'><i class="fa-regular fa-heart me-1"></i>42</span>
                            <button className='dropdown-button' title='Show Replies' type="button" data-bs-toggle="collapse" data-bs-target="#collapseReplies" aria-expanded="false" aria-controls="collapseReplies"><i class="fa-solid fa-arrow-turn-down me-1"></i>Replies (6)</button>
                          </div>
                          <div className='post-bottom-right'>
                            <span className='like-buttons'><button><i class="fa-regular fa-thumbs-up"></i></button><button><i class="fa-regular fa-thumbs-down"></i></button></span>
                          </div>
                        </div>


                        <div class="collapse multi-collapse" id="collapseReplies">
                          <div class="replies-item">
                            <div className='d-flex justify-content-start align-items-center'>
                              <div className='user-avatar'><img src={avatar4} alt='Avatar01'></img></div>
                              <div className='replier ps-2 mb-0'>
                                <span className='username'>Walleye</span>
                                <p>Replied to Onedeveloper</p>
                              </div>
                            </div>

                            <div className='post-content ps-0 mt-3'>
                              <p>
                                Summary: This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s). This strategic move aims to enhance our token's visibility, liquidity, and accessibility to a broader audience as we aim for CEXs which are ranked ≈ #30 and higher (Coingecko and Coinmarketcap as sources).

                                Details: Purpose of 1 Million HDN Allocation:
                                The allocation is intended to cover listing fees, marketing and further associated expenses for exchange platform(s), ensuring our token's widespread availability.
                              </p>
                            </div>
                          </div>
                          <div class="replies-item">
                            <div className='d-flex justify-content-start align-items-center'>
                              <div className='user-avatar'><img src={avatar5} alt='Avatar01'></img></div>
                              <div className='replier ps-2 mb-0'>
                                <span className='username'>Argon</span>
                                <p>Replied to Onedeveloper</p>
                              </div>
                            </div>

                            <div className='post-content ps-0 mt-3'>
                              <p>
                                Summary: This proposal seeks the DAO's approval to allocate 1 million HDN from the treasury to facilitate our token's listing on exchange platform(s). This strategic move aims to enhance our token's visibility, liquidity, and accessibility to a broader audience as we aim for CEXs which are ranked ≈ #30 and higher (Coingecko and Coinmarketcap as sources).

                                Details: Purpose of 1 Million HDN Allocation:
                                The allocation is intended to cover listing fees, marketing and further associated expenses for exchange platform(s), ensuring our token's widespread availability.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='card card-style-2 mb-4'>
                <div className='contributors'>
                  <h2>Top Contributors</h2>
                  <ul>
                    <li>
                      <div className='contributor'>
                        <div className='user-avatar'>
                          <img src={avatar1} alt='Avatar01'></img>
                        </div>
                        <div className='contributor-details'>
                          <h4>Mcfinity</h4>
                          <p>Team Member</p>
                        </div>
                      </div>
                      <div className='contributor-point'>
                        <span className='me-3'><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                        <span><i class="fa-regular fa-heart me-1"></i>8</span>
                      </div>
                    </li>
                    <li>
                      <div className='contributor'>
                        <div className='user-avatar'>
                          <img src={avatar2} alt='Avatar01'></img>
                        </div>
                        <div className='contributor-details'>
                          <h4>Beljora</h4>
                          <p>Team Member</p>
                        </div>
                      </div>
                      <div className='contributor-point'>
                        <span className='me-3'><i class="fa-regular fa-comment-dots me-1"></i>640</span>
                        <span><i class="fa-regular fa-heart me-1"></i>8</span>
                      </div>
                    </li>
                    <li>
                      <div className='contributor'>
                        <div className='user-avatar'>
                          <img src={avatar3} alt='Avatar01'></img>
                        </div>
                        <div className='contributor-details'>
                          <h4>Pospatriot</h4>
                          <p>Team Member</p>
                        </div>
                      </div>
                      <div className='contributor-point'>
                        <span className='me-3'><i class="fa-regular fa-comment-dots me-1"></i>420</span>
                        <span><i class="fa-regular fa-heart me-1"></i>8</span>
                      </div>
                    </li>
                    <li>
                      <div className='contributor'>
                        <div className='user-avatar'>
                          <img src={avatar4} alt='Avatar01'></img>
                        </div>
                        <div className='contributor-details'>
                          <h4>Joe Park</h4>
                          <p>Team Member</p>
                        </div>
                      </div>
                      <div className='contributor-point'>
                        <span className='me-3'><i class="fa-regular fa-comment-dots me-1"></i>360</span>
                        <span><i class="fa-regular fa-heart me-1"></i>8</span>
                      </div>
                    </li>
                    <li>
                      <div className='contributor'>
                        <div className='user-avatar'>
                          <img src={avatar5} alt='Avatar01'></img>
                        </div>
                        <div className='contributor-details'>
                          <h4>Philipp</h4>
                          <p>Team Member</p>
                        </div>
                      </div>
                      <div className='contributor-point'>
                        <span className='me-3'><i class="fa-regular fa-comment-dots me-1"></i>180</span>
                        <span><i class="fa-regular fa-heart me-1"></i>8</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='card card-style-2 mb-4'>
                <div className='hot-topics'>
                  <h2>Hot Proposals</h2>
                  <ul>
                    <li>
                      <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                      <span><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                    </li>
                    <li>
                      <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                      <span><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                    </li>
                    <li>
                      <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                      <span><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                    </li>
                    <li>
                      <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                      <span><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                    </li>
                    <li>
                      <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount addition to tokenomics</a></h3>
                      <span><i class="fa-regular fa-comment-dots me-1"></i>720</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
